var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseEmpty',[_c('ChartFilters',{staticStyle:{"margin-bottom":"0px"},attrs:{"showSelect":{
				grouping: true,
				category: true,
				month: true,
				year: true,
				type: true
			}},on:{"findTransactions":_vm.search}})],1),_c('Base',{staticClass:"container",staticStyle:{"margin-top":"20px"},attrs:{"addWidth":"30px"}},[_c('div',[_c('ChartMedia',{attrs:{"title":`Despesas por categorias`,"showXAxisLabel":true,"showZoom":true,"chartKey":3,"showLegend":true,"filters":_vm.filters}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }